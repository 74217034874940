<template>
  <SpFinder
    id="1"
    ref="finder"
    :url="{
      setting: menuSetting,
      data: menuList
    }"
    :actions="actions"
    title="资源管理"
    tree
  />
</template>

<script>
import { menuList, menuSetting, deleteMenu } from '@/api/finder';
import { mapActions } from "vuex";
import store from '@/store';

export default {
  data () {
    return {
      menuList,
      menuSetting,
      detailType: 'edit',
      actions: [{
        name: "创建资源",
        key: "create",
        slot: "header",
        type: "button",
        buttonType: "primary",
        action: {
          type: "dialog",
          title: "创建资源",
          menus: store.getters.menus,
          component: () => import("@/views/system/menu/detail.vue")
        }
      }, {
        key: 'add',
        type: 'button',
        buttonType: 'text',
        name: '添加子节点',
        action: {
          action: 'addChild',
          type: 'dialog',
          title: '添加子节点',
          menus: store.getters.menus,
          component: () => import('./detail.vue')
        }
      }, {
        key: 'edit',
        name: '编辑',
        type: 'button',
        action: {
          type: 'dialog',
          title: '编辑子节点',
          menus: store.getters.menus,
          component: () => import('./detail.vue')
        }
      }, {
        key: 'delete',
        name: '删除',
        type: 'button',
        action: {
          type: null,
          handler: function (rows) {
            this.handleDeleteMenus(rows);
          }.bind(this)
        }
      }]
    };
  },
  methods: {
    ...mapActions({
      getMenuList: 'menu/getMenuList'
    }),
    handleDeleteMenus (rows) {
      this.$confirm('请确认当前操作', '提示', {
        type: 'warning'
      }).then(async () => {
        let id = rows.map(r => r.id).join(',');
        await this.$http.post(deleteMenu, { id });
        this.refreshFinder(rows, true);
      });
    },
    refreshFinder (rows, status) {
      this.$refs.finder.refresh();
      const finder = this.$refs.finder;
      const table = finder.$refs.table.data;
      let resourceParentIdList = [];
      if (status) {
        this.getMenuList();
        resourceParentIdList = rows.map(r => r.resourceParentId);
      } else {
        resourceParentIdList.push(rows.resourceParentId);
      }
      table.map(row => {
        let index = resourceParentIdList.indexOf(row.id);
        if (index > -1 && finder.resolveInfo[row.id]) {
          finder.handleLoadNode(row, {}, finder.resolveInfo[row.id]);
        }
      });
    },
    resolveChildInfo (info) {
      return {
        resourceParentId: info.id
      };
    }
  }
};
</script>
